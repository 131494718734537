<template>
  <base-layout-two page-title="Resources" :page-default-back-link="`/users/${$route.params.id}`"
    content-class="backround-white">

    <section class="padding-x-16">
      <h1>Highlight Resources</h1>
      <p>Here you can highlight resources that will appear in the Member's Portal. Simply check the boxes of items you
        want to highlight for the guest</p>
    </section>

    <section class="margin-top-24">
      <ion-list>
        <ion-item-group v-for="category in resource_categories" :key="category">
          <ion-item-divider sticky>
            <ion-checkbox slot="start" :checked="categoryInUser(category)"
              @click="syncCategoryWithUser(category)"></ion-checkbox>
            <ion-label class="padding-8">{{ category }}</ion-label>
          </ion-item-divider>

          <ion-item v-for="resource in resourcesInCategory(category)" v-bind:key="resource.id" lines="full"
            :router-link="`/resources/${resource.id}`">
            <ion-checkbox slot="start" :checked="resourceInUser(resource)"
              @click="syncResourceWithUser(resource)"></ion-checkbox>
            <ion-label>
              <h2>{{ resource.name }}</h2>
              <p>{{ resource.location }}</p>
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </section>
  </base-layout-two>
</template>

<script>
import { IonList, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonCheckbox } from "@ionic/vue";

export default {
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonCheckbox
  },
  data() {
    return {
      resources: [],
      user_resources: [],
      resource_categories: []
    };
  },
  ionViewWillEnter() {
    this.fetchResources();
    this.fetchUserResources();
  },
  methods: {
    async fetchResources() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/resources`
        )
        .then((response) => {
          this.resources = response.data.resources;

          this.resources.sort(function (a, b) {
            return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
          })

          // Parse into Groups
          this.resource_categories = [...new Set(this.resources.map(item => item.category))]
          this.resource_categories.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1;
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchUserResources() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/resources?user_id=${this.$route.params.id}`
        )
        .then((response) => {
          this.user_resources = response.data.resources;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resourcesInCategory(category) {
      return this.resources.filter((x) => x.category === category)
    },

    categoryInUser(category) {
      let resourcesInCategory = this.resources.filter((x) => x.category === category)
      let resourcesInCategoryInUser = this.user_resources.filter((x) => x.category === category)

      return (resourcesInCategory.length === resourcesInCategoryInUser.length) ? true : false
    },

    syncCategoryWithUser(category) {
      let resourcesInCategory = this.resources.filter((x) => x.category === category)

      if (this.categoryInUser(category)) {
        for (let resource of resourcesInCategory) {
          this.unassignResourceFromUser(resource.id)
        }
      }
      else {
        for (let resource of resourcesInCategory) {
          this.assignResourceToUser(resource.id)
        }
      }
    },

    resourceInUser(resource) {
      return (this.user_resources.findIndex((x) => x.id === resource.id) >= 0) ? true : false;
    },

    syncResourceWithUser(resource) {
      if (this.resourceInUser(resource))
        this.unassignResourceFromUser(resource.id)

      else
        this.assignResourceToUser(resource.id)
    },

    async assignResourceToUser(resource_id) {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/resources/users/assign`,
        data: {
          resource_id: resource_id,
          user_id: this.$route.params.id
        },
      };

      await this.axios(config)
        .then(() => {
          this.fetchUserResources();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async unassignResourceFromUser(resource_id) {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/resources/users/unassign`,
        data: {
          resource_id: resource_id,
          user_id: this.$route.params.id
        },
      };

      await this.axios(config)
        .then(() => {
          this.fetchUserResources();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>
ion-checkbox {
  position: relative;
}
</style>